import {
	ADD_ENTITIES,
	REMOVE_ENTITIES,
} from '../actionTypes';
import schema from '../schema';
import omit from 'lodash.omit';

export const STATE_KEY = schema.project.key;

const initialState = {};

export default function(state = initialState, action) {
	const { type, payload } = action;

	switch(type) {
		case ADD_ENTITIES:
			return {
				...state,
				...payload[STATE_KEY]
			}

		case REMOVE_ENTITIES:
			return payload[STATE_KEY]
				? omit(state, payload[STATE_KEY])
				: state

		default:
			return state;
	}
}

const initialStateMetadata = {
	nextCursor: null,
};

export function metadata(state = initialStateMetadata, action) {
	const { type, payload } = action;

	switch(type) {
		case ADD_ENTITIES:
			let nextCursor = payload.nextCursor && payload.nextCursor[STATE_KEY]
				? payload.nextCursor[STATE_KEY]
				: state.nextCursor;
			return {
				...state,
				nextCursor
			}
			
		default:
			return state;
	}	
}