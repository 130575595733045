/* 
 * https://stackoverflow.com/a/39538518
 */
function delay(t, v) {
	return new Promise(function(resolve) { 
		setTimeout(resolve.bind(null, v), t)
	});
} 
Promise.prototype.delay = function(t) {
	return this.then(function(v) {
		return delay(t, v);
	});
}

/*
 * https://github.com/facebook/react/issues/5465#issuecomment-339544623
 */
const makeCancelable = promise => {
  let cancel = () => {};

  const wrappedPromise = new Promise((resolve, reject) => {
    cancel = () => {
    	console.log("Promise canceled");
      resolve = null;
      reject = null;      
    };

    promise.then(
      val => {
        if (resolve) resolve(val);
      },
      error => {
        if (reject) reject(error);
      }
    );
  });

  wrappedPromise.cancel = cancel;
  return wrappedPromise;
};
Promise.prototype.cancelable = function() {
	return makeCancelable(this);
}

export default {};