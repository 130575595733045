export const SIGN_IN = 'SIGN_IN';
export const LOG_OUT = 'LOG_OUT';
export const SET_USER = 'SET_USER';

export const ADD_ENTITIES = 'ADD_ENTITIES';
export const REMOVE_ENTITIES = 'REMOVE_ENTITIES';

export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

export const SET_STATE = 'SET_STATE';