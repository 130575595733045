import {
	SIGN_IN,
	LOG_OUT,
	SET_USER,
  SET_STATE,
  FETCH_DATA,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE
} from '../actionTypes';

const initialState = {
	token: null,
	user: null,
	loading: false,
	posts: [],
}

export default function(state = initialState, action) {
	const { type, payload } = action;

	switch(type) {
		case SIGN_IN:
			return Object.assign({}, state, { token: payload });

		case LOG_OUT:
			return Object.assign({}, state, { token: null });

		case SET_USER:
			return Object.assign({}, state, { user: payload });			

		case FETCH_DATA:
			return Object.assign({}, state, { loading: true });

		case FETCH_DATA_SUCCESS:
			return Object.assign({}, state, { loading: false, posts: payload });

		case FETCH_DATA_FAILURE:
			return Object.assign({}, state, { loading: false, error: payload });

		case SET_STATE:
			return Object.assign({}, state, payload);

		default:
			return state;
	}
}