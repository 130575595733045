import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers';
import schema from './schema';
import { normalize } from 'normalizr';
import vistaClient from 'facils/vistaClient';

export let store;

const loggerMiddleware = createLogger();
const thunkMiddleware = thunk.withExtraArgument({
	normalize,
	schema,
	client: vistaClient,
});

export default function configureStore(preloadedState) {
	const middleware = [thunkMiddleware, loggerMiddleware];
	store = createStore(rootReducer, preloadedState, applyMiddleware(...middleware));
	return store;
}