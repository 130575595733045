import client, {
  AUTHENTICATE,
	LOG_IN,
  GET_ME,
	GET_PROJECTS,
  GET_PROJECT,
  CREATE_PROJECT
} from 'apollo';

import {
  ForbiddenError,
  AuthenticationError,
  UserInputError,  
} from './errors';

const authenticate = async (passcode) => {
  try {
    let { data } = await client.mutate({
      mutation: AUTHENTICATE,
      variables: { passcode }
    });

    let token = data.authenticate.token;

    return {
      token
    }
  } catch(err) {
    throw getError(err);
  }
}

const logIn = async (email, password) => {
  try {
  	let { data } = await client.mutate({
	    mutation: LOG_IN,
	    variables: { email, password }
	  });

	  let token = data.logIn.token;

	  return {
	  	token
	  }
  } catch(err) {
  	throw getError(err);
  }
}

const fetchMe = async () => { 
  try {
    let { data } = await client.query({ 
      query: GET_ME,
      variables: null,
      fetchPolicy: "network-only"
    });

    let user = data.user
      ? data.user
      : null

    return {
      user,
    }
  } catch(err) {
    throw getError(err);
  }
}

const fetchProjects = async () => {	
  try {
    let { data } = await client.query({ 
      query: GET_PROJECTS,
      variables: null,
      fetchPolicy: "network-only"
    });

    let projects = data.projects
      ? data.projects
      : [];

    return {
      projects,
    }
  } catch(err) {
    throw getError(err);
  }
}

const fetchProject = async (id) => { 
  try {
    let { data } = await client.query({ 
      query: GET_PROJECT,
      variables: { id },
      fetchPolicy: "network-only"
    });

    let project = data.project
      ? data.project
      : null

    return {
      project,
    }
  } catch(err) {
    throw getError(err);
  }
}

const createProject = async (inputData) => {
  try {
    let { data } = await client.mutate({
      mutation: CREATE_PROJECT,
      variables: { data: inputData }
    });

    let project = data.createProject
      ? data.createProject
      : null

    return {
      project
    }
  } catch(err) {
    throw getError(err);
  }
}

const getError = (err) => {
    const { graphQLErrors, networkError } = err;

    if (graphQLErrors && graphQLErrors.length && !networkError) {
      const [{ message, extensions: { code }}] = graphQLErrors;
      switch (code) {
        case 'UNAUTHENTICATED':
          return new AuthenticationError(message);
        case 'FORBIDDEN':
          return new ForbiddenError(message);
        case 'BAD_USER_INPUT':
        	return new UserInputError(message);
        default:
          return new Error(message);
      }
    }

    return err;
}

export * from './errors';

export default {
  authenticate,
	logIn,
  fetchMe,
	fetchProjects,
  fetchProject,
  createProject,
}