import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import LoremIpsum from 'components/LoremIpsum';
import Projects from 'components/Projects';
import Project from 'components/Project';
import Authentication from 'components/Authentication';
import NewProject from 'components/NewProject';

export default [
	{
		path: '/lorem',
		component: LoremIpsum,
		public: true
	},
	{
		path: '/new-project',
		exact: true,		
		component: NewProject,
		public: false
	},	
	{
		path: '/projects/:projectId',
		component: Project,
	},
	{
		path: '/projects',
		exact: true,
		component: Projects,
		public: false
	},	
	{
		path: '/login',
		exact: true,
		component: Authentication,
		public: true
	},	
	{
		path: '/',
		exact: true,
		component: () => (<Redirect to='/login'/>),
		public: true
	},	
	{
		component: () => (<p> 404 sorry not sorry </p>)
	}
];