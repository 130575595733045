import fetch from 'cross-fetch';

import { sleep } from './util';

import {
  SIGN_IN,
  LOG_OUT,
  SET_USER,
  ADD_ENTITIES,
  REMOVE_ENTITIES,
  FETCH_DATA,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAILURE,
  SET_STATE,
} from './actionTypes';

const signIn = (token) => (dispatch) => {
  localStorage.setItem('token', token);
  return Promise.resolve(
    dispatch({
      type: SIGN_IN,
      payload: token
    })
  );
};

const logOut = () => (dispatch) => {
  localStorage.clear();
  return Promise.resolve(
    dispatch({
      type: LOG_OUT
    })
  )
};

const setUser = (user) => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: SET_USER,
      payload: user
    })
  );
}

const fetchMe = () => async (dispatch, getState, { client }) => {}

const addProjectEntities = (projects) => (dispatch, getState, { normalize, schema }) => {
  let data = normalize(projects, [schema.project]);
  return dispatch(addEntities(data.entities));
}

const addEntities = (entities, nextCursor) => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: ADD_ENTITIES,
      payload: { ...entities, nextCursor }
    })
  );
}

const removeEntities = (entities) => (dispatch) => {
  return Promise.resolve(
    dispatch({
      type: REMOVE_ENTITIES,
      payload: entities
    })
  );
}

const fetchData = () => async (dispatch, getState) => {
  dispatch({ type: FETCH_DATA });

  try {
  	await sleep(1000);
    const response = await fetch('https://jsonplaceholder.typicode.com/posts');
    const posts = await response.json();

    dispatch({ type: FETCH_DATA_SUCCESS, payload: posts });
  } catch (e) {
    dispatch({ type: FETCH_DATA_FAILURE, payload: e });
  }
}

const setState = (state) => async (dispatch) => {
  dispatch({ type: SET_STATE, payload: state });
}

export default {
  setState,
  fetchData,
  signIn,
  logOut,
  setUser,
  addEntities,
  addProjectEntities,
}