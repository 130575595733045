import './delay';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';

import notification from 'antd/lib/notification';

import { Provider } from 'react-redux';
import configureStore from './redux/store';

import './index.css';

import * as serviceWorker from './serviceWorker';

const store = configureStore({
	app: {
		token: localStorage.getItem('token'),
		// user: {
		// 	name: "Tony Teate"
		// },
	}
});

notification.config({
  duration: 3,
});

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
