import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";

import { 
  Button,
  notification,
  Layout
} from 'antd';

import routes from 'routes';

import { connect } from 'react-redux';
import actionsCreator from 'redux/actions';

import logo from '../../assets/ic_icon_1_85percentblack.svg';

const { Header, Content, Footer } = Layout;

const styles = {
  hover: {
    // border-color: rgba(0,0,0,0.09);
    // -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    // box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    borderColor: 'rgba(0,0,0,0.09)',
    boxShadow: '0 2px 8px rgba(0,0,0,0.09)'
  }
}

const mapStateToProps = (state) => ({
  token: state.app.token,
});

class App extends Component {
  componentDidMount() {
    console.log(this.props);
  }

  logOut = () => {
    const { dispatch } = this.props;
    dispatch(actionsCreator.logOut());
  }

  render() {
    const { token } = this.props;

    return (
      <Layout style={{ height: '100vh' }}>
        <Router>
          <Route 
            path='/*'
            render={(props) => (props.location.pathname !== '/login') &&
              <Header style={{ 
                background: 'white',
                zIndex: 0,
                borderBottom: '1px solid #e8e8e8',
                ...styles.hover}}>
                <img 
                  src={logo} 
                  style={{
                    width: '31px',
                    height: '31px',
                    // background: 'gray',
                    // border: '1px solid rgba(0, 0, 0, 0.65)',
                    margin: '16px 24px 16px 0',
                    float: 'left',
                  }}/>
                {token && <Button 
                  onClick={() => this.logOut()}
                  style={{ margin: '16px 0px', float: 'right'}}>
                  Log Out
                </Button>}
              </Header>}/>
          <Content 
            style={{ 
              background: '#fff', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'normal', 
              alignItems: 'center'}}>

              <Switch>
                {routes.map((route, index) =>            
                  route.public
                    ? (<Route key={index} path={route.path} exact={route.exact} component={route.component}/>)
                    : (<PrivateRoute isAuthenticated={!!this.props.token} key={index} path={route.path} exact={route.exact} component={route.component}/>)
                )}
              </Switch>
          </Content>
        </Router>
      </Layout>
    )
  }
}

const PrivateRoute = ({ isAuthenticated, component: Component, ...rest }) => {
  return (
    <Route {...rest} render={
      props =>
        isAuthenticated
        ? (<Component {...props} />) 
        : (<Redirect to='/login'/>)
      }
    />
  );
}

export default connect(mapStateToProps)(App);