import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

import { 
  Button,
  notification,
  List,
  Layout,
  Spin,
  Tabs,
  Card,
  Form,
  Input,
  Icon,
  Typography,
  Tooltip
} from 'antd';

import './Authentication.css';

import { connect } from 'react-redux';
import actionsCreator from 'redux/actions';

import logo from '../../assets/ic_icon_1.svg';

import client, { 
  VistaClientError,
  AuthenticationError,
} from 'facils/vistaClient';

const { Header, Content, Footer } = Layout;
const { TabPane } = Tabs;
const { Text, Paragraph } = Typography;

const styles = {
  hover: {
    // border-color: rgba(0,0,0,0.09);
    // -webkit-box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    // box-shadow: 0 2px 8px rgba(0,0,0,0.09);
    borderColor: 'rgba(0,0,0,0.06)',
    boxShadow: '0 2px 8px rgba(0,0,0,0.09)'
  }
}

const mapStateToProps = (state) => ({
  token: state.app.token,
  user: state.app.user,
});

class Authentication extends Component {

  state = {
    loading: false,
    passcode: '',
    email: '',
    password: ''
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    console.log(this.props);
  }

  handlePasscodeChange = (e) => {
    this.setState({ passcode: e.target.value });
  }

  handleEmailChange = (e) => {
    this.setState({ email: e.target.value });
  }  

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  }

  handleSubmitPasscode = (e) => {
    e.preventDefault();

    const passcode = this.state.passcode.trim();

    if (!passcode) {
      this.notify('Warning', 'Enter a Magic Code or Log In if you have an account', notification.warning, 5);
    } else {
      this.authenticate(passcode);      
    }
  }  

  authenticate = async (passcode) => {
    const { dispatch } = this.props;

    this.setState({ loading: true });

    try {
      let { token } = await client.authenticate(passcode);
      await dispatch(actionsCreator.signIn(token));
      // authentictation -> route change -> component is unounted -> memory leak
      // this.setState({ loading: false });
      this.fetchMe();
    } catch(err) {
      console.log(err, err instanceof Error);
      this.setState({ loading: false });
      this.notify("Something went wrong", err.message, notification.error);
    }
  };

  handleSubmitCredentials = (e) => {
    e.preventDefault();

    const email = this.state.email.trim();
    const password = this.state.password.trim();

    if (!email || !password) {
      this.notify('Warning', 'Email and password required', notification.warning);
    } else {
      this.logIn(email, password);      
    }
  }

  logIn = async (email, password) => {
    const { dispatch } = this.props;

    this.setState({ loading: true });

    try {
      let { token } = await client.logIn(email, password);
      await dispatch(actionsCreator.signIn(token));
      this.fetchMe();      
    } catch(err) {
      console.log(err, err instanceof Error);
      this.setState({ loading: false });
      this.notify("Something went wrong", err.message, notification.error);
    }    
  }

  fetchMe = async () => {
    const { history, dispatch, user } = this.props;

    if (user)
      return;

    this.setState({ loading: true });

    try {
      let { user } = await client.fetchMe();
      await dispatch(actionsCreator.setUser(user));
    } catch(err) {
      this.setState({ loading: false });
      this.notify("Something went wrong", err.message, notification.error);
    }
  }

  notify = (title, description, open, duration) => {
    const config = {
      message: title,
      description,
      duration
    };

    if (open) {
      open(config);
    } else {
      notification.info(config);
    }
  };  

  componentWillUnmount () {
  }  

  render() {
    const { match, token, user } = this.props;
    const { loading, passcode, email, password } = this.state;

    return token && user
      ? (<Redirect to="/projects"/>)
      : (<Card style={{ 
          width: '100%',
          maxWidth: 448,
          top: '10%', 
          ...styles.hover }}>
          <div 
            style={{ 
              display: 'flex',
              justifyContent: 'center',
   
            }}>
            <div 
              style={{ 
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',

                border: '1px solid rgba(0, 0, 0, 0.06)', 
                border: '1px solid rgba(0, 0, 0, 0.0)',                 
                borderRadius: '50%',
                background: 'white',
                marginTop: 24}}>
              <img src={logo} style={{ width: '72px' }} /> 
            </div>
          </div>
          <div style={{ textAlign: 'center', fontSize: 20, margin: '24px 0px 24px 0px'  }}>
            <Text>Connect to </Text><Text strong>Vista</Text>
          </div>
          <div className="tabs-container">
            <Tabs defaultActiveKey='0' onChange={this.handleTabChange}>
              <TabPane tab="Magic Code" key="0" style={{}}>
                <Form
                  layout='vertical'
                  onSubmit={this.handleSubmitPasscode}>
                  <Form.Item
                    label=''
                    validateStatus='success'
                    help=''
                    style={{paddingBottom: 0, marginBottom: 16}}>
                    <Input maxLength={6} size="large" placeholder="Enter your Magic Code" disabled={loading} value={passcode} onChange={this.handlePasscodeChange} allowClear 
                      suffix={<Tooltip title={<div>Find your Magic Code in the Vista App: Go to Profile > Settings and see the bottom of the screen</div>}><Icon type="info-circle" style={{ color: 'rgba(0,0,0,.45)' }} /></Tooltip>}
                      style={{textAlign: 'center'}}/>
                  </Form.Item>
                    <Button htmlType="submit" type="primary" size="large" onClick={this.handleSubmitPasscode} loading={loading} style={{width: '100%', marginTop: 16}}>Connect</Button>
                </Form>
              </TabPane>
              <TabPane tab="Log In" key="1" style={{}}>
                <Form
                  layout='vertical'
                  onSubmit={this.handleSubmitCredentials}>
                  <Form.Item
                    label=''
                    validateStatus='success'
                    help=''
                    style={{paddingBottom: 0, marginBottom: 0}}
                    >
                    <Input size="large"  placeholder="Email" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} value={email} disabled={loading} onChange={this.handleEmailChange} allowClear/>
                  </Form.Item>
                  <Form.Item
                    label=''
                    validateStatus='success'
                    help=''
                    style={{paddingBottom: 0, marginBottom: 16, marginTop: 16}}                      
                    >
                    <Input size="large" type="password" placeholder="Password" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} value={password} disabled={loading} onChange={this.handlePasswordChange}/>
                  </Form.Item>
                  <Button htmlType="submit" type="primary" size="large" onClick={this.handleSubmitCredentials} loading={loading} block style={{marginTop: 16}}>Log In</Button>
                </Form>
              </TabPane>
            </Tabs>
          </div>
        </Card>);
  }
}

export default connect(mapStateToProps)(Authentication);